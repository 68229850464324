var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "explorer",
      attrs: { vertical: "", "fill-height": "", justify: "flex-start" },
    },
    [
      _c("explorer-header"),
      _c("v-divider"),
      _c(
        "cs-flex",
        { attrs: { "fill-width": "", "fill-height": "", align: "stretch" } },
        [
          _c("explorer-navigation-drawer", {
            attrs: {
              fileSystemLoading: _vm.fileSystemLoading,
              currentFolderId: _vm.currentFolderId,
              currentFolderType: _vm.currentFolderType,
              currentPath: _vm.currentPath,
            },
            on: {
              navigate: _vm.navigate,
              setCurrentFolderType: _vm.setCurrentFolderType,
              handleBandwidthLimitModal: _vm.handleBandwidthLimitModal,
            },
          }),
          _c(
            "v-main",
            [
              _c(
                "cs-flex",
                {
                  staticClass: "main-container",
                  attrs: {
                    vertical: "",
                    "fill-height": "",
                    justify: "flex-start",
                  },
                },
                [
                  _c("explorer-toolbar", {
                    attrs: {
                      fileSystemLoading: _vm.fileSystemLoading,
                      currentPath: _vm.currentPath,
                      currentFolderId: _vm.currentFolderId,
                      selectedItems: _vm.selectedItems,
                      showShareModal: _vm.showShareModal,
                      currentFolderType: _vm.currentFolderType,
                      infoBarToggle: _vm.infoBarToggle,
                    },
                    on: {
                      deleteFile: _vm.deleteFile,
                      navigate: _vm.navigate,
                      setShowShareModal: _vm.setShowShareModal,
                      setInfoBarToggle: _vm.setInfoBarToggle,
                    },
                  }),
                  _c("v-divider"),
                  _c(
                    "cs-flex",
                    {
                      class: [
                        {
                          "fs-loading": _vm.fileSystemLoading,
                        },
                      ],
                      attrs: {
                        "fill-width": "",
                        "fill-height": "",
                        align: "stretch",
                        gap: "2rem",
                      },
                    },
                    [
                      _vm.currentFolderOrSearchResult &&
                      ((_vm.currentFolderOrSearchResult.childFolders &&
                        _vm.currentFolderOrSearchResult.childFolders.length) ||
                        (_vm.currentFolderOrSearchResult.childModels &&
                          _vm.currentFolderOrSearchResult.childModels.length))
                        ? _c(
                            "cs-flex",
                            {
                              staticClass: "explorer-container py-4",
                              attrs: {
                                "fill-height": "",
                                "fill-width": "",
                                align: "flex-start",
                              },
                              on: { click: _vm.unselectAll },
                            },
                            [
                              _vm.viewMode === "grid"
                                ? _c("explorer-grid-view", {
                                    attrs: {
                                      selectedItems: _vm.selectedItems,
                                      currentFolderOrSearchResult:
                                        _vm.currentFolderOrSearchResult,
                                      fadedModelKeys: _vm.fadedModelKeys,
                                    },
                                    on: {
                                      navigate: _vm.navigate,
                                      select: _vm.select,
                                      handleFileDoubleClick:
                                        _vm.handleFileDoubleClick,
                                    },
                                  })
                                : _vm._e(),
                              _vm.viewMode === "list"
                                ? _c("explorer-list-view", {
                                    attrs: {
                                      selectedItems: _vm.selectedItems,
                                      currentFolderOrSearchResult:
                                        _vm.currentFolderOrSearchResult,
                                      fadedModelKeys: _vm.fadedModelKeys,
                                    },
                                    on: {
                                      navigate: _vm.navigate,
                                      select: _vm.select,
                                      handleFileDoubleClick:
                                        _vm.handleFileDoubleClick,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("empty-search-result", {
                            attrs: { searchKeyword: _vm.searchKeyword },
                          }),
                      _c("explorer-info-tab", {
                        attrs: {
                          selectedModel: _vm.selectedModel,
                          selectedFolder: _vm.selectedFolder,
                          bandwidthLimit: _vm.bandwidthLimit,
                          bandwidthUsage: _vm.bandwidthUsage,
                          currentFolderType: _vm.currentFolderType,
                        },
                        on: {
                          showBandwidthLimitModal: function ($event) {
                            _vm.showBandwidthLimitModal = true
                          },
                          openShare: function ($event) {
                            if (!_vm.shareDisabled) {
                              _vm.showShareModal = true
                            }
                          },
                        },
                        model: {
                          value: _vm.infoBarToggle,
                          callback: function ($$v) {
                            _vm.infoBarToggle = $$v
                          },
                          expression: "infoBarToggle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }