
import { uploadModelFile } from '@/modules/model'
import {
  CsSimpleDialog,
  CsFlex,
  CsGrid,
  CsBtn,
  CsDropZone,
  CsTextarea,
  CsTagArea,
} from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'ImportFileDialog',
  components: {
    CsSimpleDialog,
    CsFlex,
    CsDropZone,
    CsBtn,
    CsGrid,
    CsTextarea,
    CsTagArea,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentPath: {
      type: Array as () => Array<{ text: string; id: string }>,
      default: [],
    },
    currentFolderId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      modelName: '',
      modelDescription: '',
      modelTags: [] as string[],
      uploadedFile: [] as File[],
      dialogLoading: false,
      dropZoneElement: undefined as undefined | HTMLElement,
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value
      },
      set(newVal: boolean): void {
        this.$emit('input', newVal)
        this.resetDialogValues()
      },
    },
    modelNameRules(): ((value: any) => true | string)[] {
      return [
        (value) => {
          if (value) return true

          return this.$tc('A név megadása kötelező')
        },
      ]
    },
    importBtnDisabled(): boolean {
      return this.modelName.trim() == '' || this.uploadedFile.length == 0
    },
    displayFolderPath(): string {
      return this.currentPath.map((path) => path.text).join(' / ') + ' /'
    },
  },
  methods: {
    resetDialogValues(): void {
      this.modelName = ''
      this.modelDescription = ''
      this.modelTags = [] as string[]
      this.uploadedFile = [] as File[]
    },
    async handleImportBtnClick(): Promise<void> {
      this.dialogLoading = true

      await uploadModelFile(
        this.modelName,
        this.modelDescription,
        this.modelTags,
        this.currentFolderId,
        '2.0',
        this.uploadedFile
      )

      this.dialogLoading = false
      this.$emit('input', false)
    },
  },
  watch: {
    value() {
      this.resetDialogValues()

      if (this.value) {
        this.$nextTick(() => {
          this.dropZoneElement =
            ((this.$refs.dropZoneRef as InstanceType<typeof CsFlex>).$el as HTMLElement) ||
            undefined
        })
      }
    },
    uploadedFile() {
      if (this.uploadedFile.length === 0) return

      this.modelName = this.uploadedFile[0].name.substring(
        0,
        this.uploadedFile[0].name.lastIndexOf('.')
      )
    },
  },
})
