
import Vue from 'vue'
import {
  CsBtn,
  CsNavigationDrawer,
  CsFiletree,
  CsFlex,
  CsUsageProgress,
  CsSimpleDialog,
} from '@consteel/csuetify'
import {
  convertHydratedToDirectoryTree,
  FileTreeNode,
  hydrate,
} from '@/services/steelspacefilesystem.service'
import { getUserFoldersSubscribe, FolderType, createFolder } from '@/modules/folder'
import { getUserModelsSubscribe } from '@/modules/model'
import RequestMoreBandwidthDialog from '@/components/Dialogs/RequestMoreBandwidthDialog.vue'
import {
  getBandwidthLimitationSubscribe,
  getBandwidthUsageSubscribe,
} from '../../modules/userService'
import ImportModelFileDialog from '@/components/Dialogs/ImportModelFileDialog.vue'
import NewFolderDialog from '../Dialogs/NewFolderDialog.vue'

export default Vue.extend({
  name: 'ExplorerNavigationDrawer',
  components: {
    CsFiletree,
    CsBtn,
    CsFlex,
    CsUsageProgress,
    CsNavigationDrawer,
    RequestMoreBandwidthDialog,
    ImportModelFileDialog,
    CsSimpleDialog,
    NewFolderDialog,
  },
  data() {
    return {
      createProjectDialog: false,
      showRequestMoreModal: false,
      showCreateFolderDialog: false,
      newFolderDialogLoading: false,
      showImportFileDialog: false,
      selectedAddNewItem: null,
      addNewMenuOpen: false,
    }
  },
  props: {
    fileSystemLoading: {
      type: Boolean,
      default: false,
    },
    currentFolderId: {
      type: String,
    },
    currentFolderType: {
      type: String as () => FolderType,
    },
    showBandwidthLimitModal: {
      type: Boolean,
      default: false,
    },
    currentPath: {
      type: Array as () => Array<{ text: string; id: string }>,
    },
  },
  computed: {
    folderTree(): FileTreeNode | undefined {
      return convertHydratedToDirectoryTree(
        hydrate(
          getUserFoldersSubscribe(),
          getUserModelsSubscribe(),
          this.currentFolderType,
          'alphabetical'
        )
      )
    },
    bandwidthLimit(): number {
      return getBandwidthLimitationSubscribe()
    },
    bandwidthUsage(): number {
      return getBandwidthUsageSubscribe()
    },
    addNewItems(): { click: () => void; text: string; value: number }[] {
      return [
        {
          text: this.$t('Folder').toString(),
          click: () => (this.showCreateFolderDialog = !this.showCreateFolderDialog),
          value: 1,
        },
        {
          text: this.$t('Import file').toString(),
          click: () => (this.showImportFileDialog = !this.showImportFileDialog),
          value: 2,
        },
      ]
    },
  },
  methods: {
    handleClickCreateProjectButton() {
      this.createProjectDialog = true
    },
    navigate(folderId: number, skipHistory = false) {
      this.$emit('navigate', { folderId: folderId.toString(), skipHistory: skipHistory })
    },
    setCurrentFolderType(newType: FolderType) {
      this.$emit('setCurrentFolderType', newType)
    },
    handleBandwidthLimitModal(value: boolean) {
      this.$emit('handleBandwidthLimitModal', value)
    },
    async createFolder(name: string, parentId?: string): Promise<void> {
      this.newFolderDialogLoading = true
      await createFolder(name, parentId || this.currentFolderId)
      this.newFolderDialogLoading = false

      this.showCreateFolderDialog = false
    },
  },
  watch: {
    showImportFileDialog(value: boolean): void {
      if (!value) this.selectedAddNewItem = null
    },
    showCreateFolderDialog(value: boolean): void {
      if (!value) this.selectedAddNewItem = null
    },
  },
})
