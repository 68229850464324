var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        width: 600,
        title: _vm.$t("Import File"),
        persistent: "",
        "min-height": "25rem",
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "cs-flex",
                { attrs: { fillWidth: "", justify: "end", gap: "2rem" } },
                [
                  _c(
                    "cs-btn",
                    {
                      attrs: { outlined: "", disabled: _vm.dialogLoading },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  ),
                  _c(
                    "cs-btn",
                    {
                      attrs: {
                        disabled: _vm.importBtnDisabled,
                        loading: _vm.dialogLoading,
                      },
                      on: { click: _vm.handleImportBtnClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Import")) + " ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "cs-flex",
        { ref: "dropZoneRef", staticClass: "mb-6", attrs: { vertical: "" } },
        [
          _c(
            "div",
            [
              _c("cs-drop-zone", {
                staticClass: "mx-9",
                attrs: {
                  required: "",
                  acceptedFileExtensions: [".ifc", ".smadsteel"],
                  disabled: _vm.dialogLoading,
                  dropZoneElement: _vm.dropZoneElement,
                },
                model: {
                  value: _vm.uploadedFile,
                  callback: function ($$v) {
                    _vm.uploadedFile = $$v
                  },
                  expression: "uploadedFile",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mt-6 mb-4" }, [
            _vm._v(_vm._s(_vm.$t("File details"))),
          ]),
          _c(
            "cs-grid",
            {
              attrs: {
                "fill-width": "",
                "template-columns": "1.2fr 5fr",
                justify: "stretch",
                align: "start",
              },
            },
            [
              _c("div", { staticClass: "pt-2" }, [
                _vm._v(_vm._s(_vm.$t("Name"))),
              ]),
              _c("cs-textarea", {
                attrs: {
                  outlined: "",
                  rules: _vm.modelNameRules,
                  dense: "",
                  "no-resize": "",
                  "auto-grow": "",
                  rows: "1",
                  "row-height": "24",
                  disabled: _vm.dialogLoading,
                },
                model: {
                  value: _vm.modelName,
                  callback: function ($$v) {
                    _vm.modelName = $$v
                  },
                  expression: "modelName",
                },
              }),
              _c("div", { staticClass: "mb-5" }, [
                _vm._v(_vm._s(_vm.$t("Path"))),
              ]),
              _c("div", { staticClass: "import-model-file-dialog-path" }, [
                _vm._v(_vm._s(_vm.displayFolderPath)),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.$t("Description")))]),
              _c("cs-textarea", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "no-resize": "",
                  "auto-grow": "",
                  rows: "2",
                  "row-height": "24",
                  placeholder: _vm.$t("Add a small description of the file."),
                  disabled: _vm.dialogLoading,
                },
                model: {
                  value: _vm.modelDescription,
                  callback: function ($$v) {
                    _vm.modelDescription = $$v
                  },
                  expression: "modelDescription",
                },
              }),
              _c("div", [_vm._v(_vm._s(_vm.$t("Tags")))]),
              _c("cs-tag-area", {
                attrs: {
                  placeholder: _vm.$t(
                    "Start typing and hit Enter to add a tag."
                  ),
                  disabled: _vm.dialogLoading,
                  dense: "",
                },
                model: {
                  value: _vm.modelTags,
                  callback: function ($$v) {
                    _vm.modelTags = $$v
                  },
                  expression: "modelTags",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }