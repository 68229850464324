
import Vue from 'vue'
import { CsFlex, CsExplorerBreadcrumbs, CsTextField, CsSelect } from '@consteel/csuetify'
import {
  FolderType,
  createFolder,
  SteelspaceFolder,
  getUserFoldersSubscribe,
  moveFolder,
} from '@/modules/folder'
import {
  moveModel,
  SteelspaceModelUserShare,
  SteelspaceModel,
  getUserModelsSubscribe,
  getPublicModels,
} from '@/modules/model'
import { Ordering } from '@/services/steelspacefilesystem.service'
import NewFolderDialog from '@/components/Dialogs/NewFolderDialog.vue'
import MoveToDialog from '../Dialogs/MoveToDialog.vue'
import ShareDialog from '../Dialogs/ShareDialog.vue'
import {
  setExplorerOrderBy,
  setExplorerViewMode,
  ExplorerViewMode,
  explorerStore,
  setExplorerSearchKeyword,
} from '@/store/explorer.store'
import ViewModeSelect from './ViewModeSelect.vue'
import ExplorerDeleteFile from './ExplorerDeleteFile.vue'

export default Vue.extend({
  name: 'ExplorerNavigationDrawer',
  components: {
    CsFlex,
    CsExplorerBreadcrumbs,
    NewFolderDialog,
    MoveToDialog,
    ShareDialog,
    CsTextField,
    CsSelect,
    ViewModeSelect,
    ExplorerDeleteFile,
  },
  data() {
    return {
      creationTarget: '',
      showNewFolderModal: false,
      newFolderDialogLoading: false,
      moveToDialogLoading: false,
      showMoveToModal: false,
      orderByOptions: [
        { value: 'alphabetical', text: 'A-Z' },
        { value: 'recent', text: 'Recent' },
      ] as { value: Ordering; text: string }[],
    }
  },
  props: {
    fileSystemLoading: {
      type: Boolean,
      default: false,
    },
    currentPath: {
      type: Array as () => Array<{ text: string; id: string }>,
    },
    currentFolderId: {
      type: String,
    },
    selectedItems: {
      type: Array as () => string[],
    },
    showShareModal: {
      type: Boolean,
      default: false,
    },
    infoBarToggle: {
      type: Boolean,
      default: false,
    },
    currentFolderType: {
      type: String as () => FolderType,
    },
  },
  computed: {
    folders(): SteelspaceFolder[] {
      return getUserFoldersSubscribe()
    },
    models(): SteelspaceModel[] {
      return getUserModelsSubscribe()
    },
    moveDisabled(): boolean {
      return this.selectedItems.length === 0 || this.currentFolderType === 'public'
    },
    selectedModelShares(): SteelspaceModelUserShare[] {
      if (this.selectedModel) {
        return this.selectedModel.getShares()
      } else {
        return new Array<SteelspaceModelUserShare>()
      }
    },
    selectedModel(): undefined | SteelspaceModel {
      if (this.selectedItems.length === 1) {
        const model =
          getUserModelsSubscribe().find((model) => model.id === this.selectedItems[0]) ||
          this.publicModels?.find((model) => model.id === this.selectedItems[0])
        if (model) return model
      }
      return undefined
    },
    publicModels(): SteelspaceModel[] | null {
      return getPublicModels()
    },
    deleteDisabled(): boolean {
      return (
        !(this.currentFolderType === 'own' || this.currentFolderType === 'shared') ||
        this.selectedItems.length !== 1 ||
        !(this.selectedFolder || this.selectedModel)
      )
    },
    shareDisabled(): boolean {
      return (
        this.currentFolderType !== 'own' ||
        this.selectedItems.length !== 1 ||
        this.selectedModel?.id.length === 0 ||
        !!this.selectedFolder
      )
    },
    createDisabled(): boolean {
      return this.currentFolderType === 'public'
    },
    selectedFolder(): undefined | SteelspaceFolder {
      if (this.selectedItems.length === 1) {
        const folder = getUserFoldersSubscribe().find((folder) => {
          return folder.id === this.selectedItems[0]
        })
        if (folder) return folder
      }
      return undefined
    },
    searchKeyword(): string {
      return explorerStore.searchKeyword
    },
    orderBy(): Ordering {
      return explorerStore.orderBy
    },
    viewMode(): ExplorerViewMode {
      return explorerStore.viewMode
    },
  },
  methods: {
    openNewFolderModal(folderId: string): void {
      this.creationTarget = folderId
      this.showNewFolderModal = true
    },
    async createFolder(name: string, parentId?: string): Promise<void> {
      this.newFolderDialogLoading = true
      await createFolder(name, parentId || this.currentFolderId)
      this.newFolderDialogLoading = false
      this.showNewFolderModal = false
    },
    async moveFiles(moveTarget: string): Promise<void> {
      this.moveToDialogLoading = true
      const folders = this.folders.filter((folder) => {
        return this.selectedItems.includes(folder.id)
      })
      const models = this.models.filter((model) => {
        return this.selectedItems.includes(model.id)
      })
      await Promise.all([
        ...folders.map(async (folder) => {
          return moveFolder(folder.id, moveTarget)
        }),
        ...models.map(async (model) => {
          return moveModel(model.id, moveTarget)
        }),
      ])
      this.moveToDialogLoading = false
      this.showMoveToModal = false
      this.$emit('setSelectedItems', [])
    },
    handleChangeSearchKeyword: setExplorerSearchKeyword,
    handleChangeViewMode: setExplorerViewMode,
    handleChangeOrderBy: setExplorerOrderBy,
    navigate(id: string): void {
      this.$emit('navigate', { folderId: id })
    },
    setShowShareModal(value: boolean) {
      this.$emit('setShowShareModal', value)
    },
    setInfoBarToggle(value: boolean) {
      this.$emit('setInfoBarToggle', value)
    },
  },
})
