
import { CsGrid, CsExplorerFolderCard, CsExplorerFileCard } from '@consteel/csuetify'
import DefaultPreviewSvg from '../../assets/DefaultPreview.svg.vue'
import { ModelStatusType } from '../../modules/model/types'
import Vue from 'vue'
import ConsteelBadgeSvg from '@/assets/ConsteelBadge.svg.vue'
import IfcConverterBadgeSvg from '@/assets/IfcConverterBadge.svg.vue'
import { OriginType } from '@/modules/model/types/SteelspaceModelResponse'

export default Vue.extend({
  name: 'ExplorerGridView',
  components: {
    CsGrid,
    CsExplorerFolderCard,
    CsExplorerFileCard,
    DefaultPreviewSvg,
    ConsteelBadgeSvg,
    IfcConverterBadgeSvg,
  },
  props: {
    selectedItems: {
      type: Array as () => Array<string>,
      default: [],
    },
    fadedModelKeys: {
      type: Array as () => Array<string>,
      default: [],
    },
    currentFolderOrSearchResult: {},
  },
  methods: {
    isModelOpenable(modelStatus: ModelStatusType): boolean {
      return modelStatus !== 'invalid'
    },
    navigate(folderId: string, skipHistory = false): void {
      this.$emit('navigate', { folderId, skipHistory })
    },
    select(event: MouseEvent, id: string): void {
      this.$emit('select', { event, id })
    },
    handleFileDoubleClick(modelId: string): void {
      this.$emit('handleFileDoubleClick', modelId)
    },
    modelUploadBadge(uploadOrigin: OriginType) {
      switch (uploadOrigin) {
        case 'consteel':
          return ConsteelBadgeSvg
        case 'ifcConverter':
          return IfcConverterBadgeSvg
      }
    },
  },
})
