
import {
  FolderType,
  deleteFolder,
  HydratedSteelspaceFolder,
  SteelspaceFolder,
  getUserFoldersSubscribe,
  setFolderLoading,
  folderStore,
} from '@/modules/folder'
import {
  getUserModelsSubscribe,
  modelStore,
  setModelLoading,
  SteelspaceModel,
  getPublicModels,
  SteelspaceModelUserShare,
  modifyShare,
  removeShare,
  deleteModel,
} from '@/modules/model'
import {
  deleteExpiredPreviewsFromCache,
  getModelFromCache,
  getModelPreviewUrlFromCache,
  getKeys,
} from '@/services/cache.service'
import {
  getHydratedFolder,
  getPathToFolder,
  hydrate,
  searchInFolder,
} from '@/services/steelspacefilesystem.service'
import {
  ExplorerViewMode,
  setExplorerSearchKeyword,
  setDeleteFileLoading,
  setDeleteFileShowDialog,
  explorerStore,
} from '@/store/explorer.store'
import { CsFlex } from '@consteel/csuetify'
import EmptySearchResult from '@/components/Explorer/EmptySearchResult.vue'
import ExplorerGridView from '@/components/Explorer/ExplorerGridView.vue'
import ExplorerListView from '@/components/Explorer/ExplorerListView.vue'
import ExplorerInfoTab from '@/components/Explorer/ExplorerInfoTab.vue'
import ExplorerNavigationDrawer from '@/components/Explorer/ExplorerNavigationDrawer.vue'
import ExplorerToolbar from '@/components/Explorer/ExplorerToolbar.vue'
import ExplorerHeader from '@/components/Explorer/ExplorerHeader.vue'
import Vue from 'vue'
import { getBandwidthLimitationSubscribe, getBandwidthUsageSubscribe } from '../modules/userService'

export default Vue.extend({
  name: 'Explorer',
  components: {
    CsFlex,
    ExplorerHeader,
    ExplorerNavigationDrawer,
    ExplorerToolbar,
    EmptySearchResult,
    ExplorerGridView,
    ExplorerListView,
    ExplorerInfoTab,
  },
  data: () => ({
    currentFolderId: '',
    currentFolderType: 'own' as FolderType,
    selectedItems: [] as string[],
    infoBarToggle: false,
    showShareModal: false,
    showBandwidthLimitModal: false,
    fadedModelKeys: [] as string[],
    historyTab: 0,
  }),
  computed: {
    searchKeyword(): string {
      return explorerStore.searchKeyword
    },
    viewMode(): ExplorerViewMode {
      return explorerStore.viewMode
    },
    folders(): SteelspaceFolder[] {
      return getUserFoldersSubscribe()
    },
    models(): SteelspaceModel[] {
      return getUserModelsSubscribe()
    },
    publicModels(): SteelspaceModel[] | null {
      return getPublicModels()
    },
    bandwidthLimit(): number {
      return getBandwidthLimitationSubscribe()
    },
    bandwidthUsage(): number {
      return getBandwidthUsageSubscribe()
    },
    shareDisabled(): boolean {
      return (
        this.currentFolderType !== 'own' ||
        this.selectedItems.length !== 1 ||
        this.selectedModel?.id.length === 0
      )
    },
    currentPath(): { text: string; id: string }[] {
      return getPathToFolder(getUserFoldersSubscribe(), this.currentFolderId).map((pathItem) => {
        return { text: pathItem.name, id: pathItem.id }
      })
    },
    currentFolder(): HydratedSteelspaceFolder | undefined {
      return getHydratedFolder(
        getUserFoldersSubscribe(),
        getUserModelsSubscribe(),
        getPublicModels(),
        this.currentFolderId,
        explorerStore.orderBy
      )
    },
    rootFolder(): HydratedSteelspaceFolder | undefined {
      return hydrate(
        getUserFoldersSubscribe(),
        getUserModelsSubscribe(),
        this.currentFolderType,
        explorerStore.orderBy
      )
    },
    currentFolderOrSearchResult(): HydratedSteelspaceFolder | undefined {
      return this.searchKeyword && this.currentFolder
        ? searchInFolder(this.currentFolder, this.searchKeyword)
        : this.currentFolder
    },
    fileSystemLoading: {
      set(value: boolean): void {
        setModelLoading(value)
        setFolderLoading(value)
      },
      get(): boolean {
        return modelStore.loading || folderStore.loading
      },
    },
    selectedModel(): undefined | SteelspaceModel {
      if (this.selectedItems.length === 1) {
        const model =
          getUserModelsSubscribe().find((model) => model.id === this.selectedItems[0]) ||
          this.publicModels?.find((model) => model.id === this.selectedItems[0])
        if (model) return model
      }
      return undefined
    },
    selectedFolder(): undefined | SteelspaceFolder {
      if (this.selectedItems.length === 1) {
        const folder = getUserFoldersSubscribe().find((folder) => {
          return folder.id === this.selectedItems[0]
        })
        if (folder) return folder
      }
      return undefined
    },
  },
  methods: {
    async computeFadedModelKeys(): Promise<void> {
      const keys = await getKeys()
      const models = [...this.models, ...(this.publicModels ?? [])]

      const fadedModelKeys = models
        .filter((model) => {
          const previewUrl = getModelPreviewUrlFromCache(model)
          const modelIsOutOfBandwidth = model.size > this.bandwidthLimit - this.bandwidthUsage
          const modelIsNotInCacheWithPreviewUrl = !keys.includes(model.id) || !previewUrl

          return modelIsOutOfBandwidth && modelIsNotInCacheWithPreviewUrl
        })
        .map((model) => model.id)

      this.fadedModelKeys = fadedModelKeys
    },
    navigate({ folderId, skipHistory = false }: { folderId: string; skipHistory: boolean }): void {
      let _folderId = folderId
      if (['own', 'shared'].includes(folderId)) {
        this.setCurrentFolderType(folderId as FolderType)
        _folderId = ''
      }

      this.currentFolderId = _folderId
      this.unselectAll()
      this.infoBarToggle = false
      setExplorerSearchKeyword('')

      if (!skipHistory) {
        history.pushState(null, 'Steelspace Explorer', `/explorer/${_folderId}`)
      }
    },
    select({ event, id }: { event: MouseEvent; id: string }): void {
      if (!event.ctrlKey) {
        this.selectedItems = []
      }

      if (this.selectedItems.includes(id)) {
        this.selectedItems.splice(this.selectedItems.indexOf(id), 1)
      } else {
        this.selectedItems.push(id)
      }
    },
    unselectAll(event?: MouseEvent): void {
      if (!event || !event.ctrlKey) {
        this.selectedItems = []
      }
    },
    setCurrentFolderType(newType: FolderType, skipHistory = false): void {
      this.currentFolderType = newType
      if (newType === 'public') {
        this.navigate({ folderId: newType, skipHistory: skipHistory })
      } else {
        this.navigate({ folderId: this.rootFolder?.id || '', skipHistory: skipHistory })
      }
    },
    async deleteFile(): Promise<void> {
      setDeleteFileLoading(true)
      if (this.selectedFolder) {
        await deleteFolder(this.selectedFolder.id)
      } else if (this.selectedModel) {
        await deleteModel(this.selectedModel.id)
      }
      setDeleteFileLoading(false)
      setDeleteFileShowDialog(false)
    },
    handleBandwidthLimitModal(value: boolean) {
      this.showBandwidthLimitModal = value
    },
    async modifyShare(share: SteelspaceModelUserShare): Promise<void> {
      if (this.selectedModel) {
        await modifyShare(this.selectedModel.id, share)
      }
    },
    async removeShare(share: SteelspaceModelUserShare): Promise<void> {
      if (this.selectedModel) {
        await removeShare(this.selectedModel.id, share)
      }
    },
    handleLinkNavigation(): void {
      if (!this.fileSystemLoading) {
        // Ha van paraméter és az lézetik a mappák között akkor oda navigálunk
        if (
          this.$route.params.folderId &&
          this.folders.find((item) => item.id === this.$route.params.folderId)
        ) {
          this.navigate({ folderId: this.$route.params.folderId, skipHistory: true })

          // Ez itt nem halhat meg elvileg soha, ha már befejeztük a töltést
          if (this.currentFolder) {
            this.currentFolderType = this.currentFolder?.type
          }
        } else if (this.$route.params.folderId === 'public') {
          this.setCurrentFolderType('public', true)
        } else {
          // Visszafele lépkedve a history-ban az üres explorer-hez érkezünk vagy nem létező mappa
          this.setCurrentFolderType('own', true)
          if (this.$route.params.folderId) {
            // Van folderId, de nem létező helyre mutat
            history.replaceState(null, 'Steelspace Explorer', '/explorer')
          }
        }
      } else {
        setTimeout(this.handleLinkNavigation, 200)
      }
    },
    async handleFileDoubleClick(modelId: string): Promise<void> {
      const modelToOpen =
        this.models.find((model) => model.id === modelId) ||
        this.publicModels?.find((model) => model.id === modelId)

      if (!modelToOpen) {
        throw new Error('This should never happen: no model to open')
      }

      if (modelToOpen.status === 'invalid') return

      const cachedModel = await getModelFromCache(modelToOpen.id, modelToOpen.lastModificationDate)

      if (cachedModel || this.bandwidthLimit - this.bandwidthUsage > modelToOpen.size) {
        this.$router.push(`/model/${modelId}`)
      } else {
        this.showBandwidthLimitModal = true
      }
    },
    setShowShareModal(value: boolean) {
      this.showShareModal = value
    },
    setInfoBarToggle(value: boolean) {
      this.infoBarToggle = value
    },
  },
  watch: {
    currentFolderType(): void {
      this.historyTab = 0
    },
    async models(): Promise<void> {
      await this.computeFadedModelKeys()
    },
    async publicModels(): Promise<void> {
      await this.computeFadedModelKeys()
    },
    async bandwidthUsage(): Promise<void> {
      await this.computeFadedModelKeys()
    },
    searchKeyword(): void {
      this.selectedItems = []
    },
    rootFolder(): void {
      if (!this.currentFolderId) this.currentFolderId = this.rootFolder?.id || ''
    },
  },
  created(): void {
    window.onpopstate = this.handleLinkNavigation
    this.handleLinkNavigation()

    deleteExpiredPreviewsFromCache()
  },
  async mounted() {
    await this.computeFadedModelKeys()
  },
})
