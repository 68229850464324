
import Vue from 'vue'
import { CsSimpleTable, CsFlex } from '@consteel/csuetify'
import DefaultPreviewSvg from '../../assets/DefaultPreview.svg.vue'
import { formatSize } from '../../services/filesizeformatter.service'

export default Vue.extend({
  name: 'ExplorerListView',
  components: {
    CsSimpleTable,
    DefaultPreviewSvg,
    CsFlex,
  },
  props: {
    selectedItems: {
      type: Array as () => Array<string>,
      default: [],
    },
    fadedModelKeys: {
      type: Array as () => Array<string>,
      default: [],
    },
    currentFolderOrSearchResult: {},
  },
  methods: {
    navigate(folderId: string, skipHistory = false): void {
      this.$emit('navigate', { folderId, skipHistory })
    },
    select(event: MouseEvent, id: string): void {
      this.$emit('select', { event, id })
    },
    handleFileDoubleClick(modelId: string): void {
      this.$emit('handleFileDoubleClick', modelId)
    },
    formatSize: formatSize,
  },
})
