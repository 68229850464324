var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.value
    ? _c("cs-history-tabs", {
        scopedSlots: _vm._u(
          [
            {
              key: "info",
              fn: function () {
                return [
                  _vm.value && _vm.selectedModel
                    ? _c("cs-model-info", {
                        attrs: {
                          title: _vm.selectedModel.name,
                          thumbnail: _vm.selectedModel.previewUrl,
                          description: _vm.selectedModel.description,
                          owner: _vm.selectedModel.originUserData,
                          infos: _vm.getFileInfo(_vm.selectedModel),
                          shares: _vm.selectedModel.getShares(),
                          tags: _vm.selectedModel.tags,
                        },
                        on: {
                          close: function ($event) {
                            _vm.value = !_vm.value
                          },
                          openShare: function ($event) {
                            return _vm.$emit("openShare")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            !_vm.selectedModel.previewUrl
                              ? {
                                  key: "imageDefaultSlot",
                                  fn: function () {
                                    return [_c("default-preview-svg")]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm.value && _vm.selectedFolder
                    ? _c("cs-model-info", {
                        attrs: {
                          title: _vm.selectedFolder.name,
                          infos: _vm.getFolderInfo(_vm.selectedFolder),
                          folder: "",
                        },
                        on: {
                          close: function ($event) {
                            _vm.value = !_vm.value
                          },
                        },
                      })
                    : _vm.value
                    ? _c("cs-model-info", {
                        attrs: { empty: "", title: "" },
                        on: {
                          close: function ($event) {
                            _vm.value = !_vm.value
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "history",
              fn: function () {
                return [
                  _vm.selectedModel
                    ? _c("cs-model-history", {
                        attrs: {
                          items: _vm.historyItems,
                          model: _vm.selectedModel,
                          errorMessage: _vm.historyErrorMessage,
                          errorTitle: _vm.historyErrorTitle,
                          errorIcon: _vm.historyErrorIcon,
                          errorIconColor: _vm.historyErrorIconColor,
                          loading: _vm.historyLoading,
                          hideEdit: _vm.currFolderIsPublic,
                          hideCreate: _vm.currFolderIsPublic,
                          hideRestore: _vm.currFolderIsPublic,
                          hideDelete: _vm.currFolderIsPublic,
                        },
                        on: {
                          download: _vm.handleHistoryItemDownload,
                          edit: _vm.handleHistoryItemEdit,
                          restore: _vm.handleHistoryItemRestore,
                          delete: _vm.handleHistoryItemDelete,
                          open: _vm.handleHistoryItemOpen,
                          create: _vm.handleHistoryItemCreate,
                          errorCancel: _vm.handleHistoryErrorCancel,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          745323918
        ),
        model: {
          value: _vm.historyTab,
          callback: function ($$v) {
            _vm.historyTab = $$v
          },
          expression: "historyTab",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }