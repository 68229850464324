
import { SteelspaceFolder } from '@/modules/folder'
import { getHydratedFolder, getPathToFolder } from '@/services/steelspacefilesystem.service'
import { CsBtn, CsListItem, CsExplorerBreadcrumbs, CsSimpleDialog } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'MoveToDialog',
  components: {
    CsSimpleDialog,
    CsBtn,
    CsExplorerBreadcrumbs,
    CsListItem,
  },
  props: {
    value: Boolean,
    folders: {
      required: true,
      type: Array as () => SteelspaceFolder[],
    },
    currentFolderId: String,
    loading: Boolean,
    selectedItems: Array as () => string[],
  },
  data() {
    return {
      localCurrentFolderId: this.currentFolderId,
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value
      },
      set(newVal: boolean): void {
        this.$emit('input', newVal)
      },
    },
    currentPath(): { text: string; id: string }[] {
      return getPathToFolder(this.folders, this.localCurrentFolderId).map((pathItem) => {
        return { text: pathItem.name, id: pathItem.id }
      })
    },
    folderMap(): unknown {
      return getHydratedFolder(this.folders, null, null, this.localCurrentFolderId, 'alphabetical')
    },
    allowedLocation(): boolean {
      return !this.selectedItems.includes(this.localCurrentFolderId)
    },
  },
  methods: {
    moveFiles() {
      this.$emit('moveFiles', this.localCurrentFolderId)
    },
    navigate(folderId: string) {
      this.localCurrentFolderId = folderId
    },
    openNewFolderDialog() {
      this.$emit('createNewFolder', this.localCurrentFolderId)
    },
  },
  watch: {
    currentFolderId(newVal) {
      this.localCurrentFolderId = newVal
    },
    value(newVal: string | boolean) {
      if (!newVal) {
        // To delay change, waiting for dialog close animation
        setTimeout(() => {
          this.localCurrentFolderId = this.currentFolderId
        }, 500)
      } else {
        // Opening event
        this.localCurrentFolderId = this.currentFolderId
      }
    },
  },
})
