var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-navigation-drawer",
    {
      staticClass: "py-4 px-6 nav-drawer",
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "cs-flex",
                { staticClass: "px-2", attrs: { vertical: "", gap: "1rem" } },
                [
                  _c(
                    "cs-flex",
                    { attrs: { vertical: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pb-5 grey--text text--lighten-3",
                          attrs: { "x-large": "" },
                        },
                        [_vm._v("mdi-cloud")]
                      ),
                      _c("cs-usage-progress", {
                        attrs: {
                          title: "Bandwidth",
                          unit: "MB",
                          max: Math.floor(_vm.bandwidthLimit / 1000),
                          current: Math.floor(_vm.bandwidthUsage / 1000),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "cs-flex",
                    { attrs: { noContentStretch: "" } },
                    [
                      _c("request-more-bandwidth-dialog", {
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "cs-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { outlined: "" } },
                                      "cs-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(_vm._s(_vm.$t("Request More")))]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.showRequestMoreModal,
                          callback: function ($$v) {
                            _vm.showRequestMoreModal = $$v
                          },
                          expression: "showRequestMoreModal",
                        },
                      }),
                      _c(
                        "cs-simple-dialog",
                        {
                          attrs: {
                            maxWidth: 500,
                            title: _vm.$t("Bandwidth limit reached"),
                          },
                          model: {
                            value: _vm.showBandwidthLimitModal,
                            callback: function ($$v) {
                              _vm.showBandwidthLimitModal = $$v
                            },
                            expression: "showBandwidthLimitModal",
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "You have reached your monthly bandwidth quota."
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "cs-flex",
                            { attrs: { justify: "flex-end" } },
                            [
                              _c(
                                "cs-btn",
                                {
                                  staticClass: "mr-4",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: () => {
                                      _vm.handleBandwidthLimitModal(false)
                                      _vm.requestMore()
                                      _vm.showRequestMoreModal = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Send request")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "cs-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBandwidthLimitModal(
                                        false
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("Ok")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.fileSystemLoading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm._e(),
      _c("import-model-file-dialog", {
        attrs: {
          currentPath: _vm.currentPath,
          currentFolderId: _vm.currentFolderId,
        },
        model: {
          value: _vm.showImportFileDialog,
          callback: function ($$v) {
            _vm.showImportFileDialog = $$v
          },
          expression: "showImportFileDialog",
        },
      }),
      _c("new-folder-dialog", {
        attrs: {
          creationTarget: _vm.currentFolderId,
          loading: _vm.newFolderDialogLoading,
        },
        on: { createFolder: _vm.createFolder },
        model: {
          value: _vm.showCreateFolderDialog,
          callback: function ($$v) {
            _vm.showCreateFolderDialog = $$v
          },
          expression: "showCreateFolderDialog",
        },
      }),
      _c(
        "v-menu",
        {
          attrs: { bottom: "", "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "add-new-btn",
                          class: { "add-new-menu-open": _vm.addNewMenuOpen },
                          attrs: {
                            block: "",
                            color: "primary",
                            dark: "",
                            large: "",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "cs-flex",
                        { attrs: { fillWidth: "", justify: "space-between" } },
                        [
                          _c(
                            "cs-flex",
                            [
                              _c("v-icon", [_vm._v("mdi-plus")]),
                              _vm._v(_vm._s(_vm.$t("Add new"))),
                            ],
                            1
                          ),
                          _c(
                            "v-icon",
                            { staticClass: "add-new-btn-open-icon" },
                            [_vm._v("mdi-chevron-down")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.addNewMenuOpen,
            callback: function ($$v) {
              _vm.addNewMenuOpen = $$v
            },
            expression: "addNewMenuOpen",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.selectedAddNewItem,
                    callback: function ($$v) {
                      _vm.selectedAddNewItem = $$v
                    },
                    expression: "selectedAddNewItem",
                  },
                },
                _vm._l(_vm.addNewItems, function (item, index) {
                  return _c(
                    "v-list-item",
                    { key: index, on: { click: item.click } },
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "cs-btn",
        {
          staticClass: "justify-start my-2",
          attrs: {
            color: "gray",
            block: "",
            large: "",
            outlined: _vm.currentFolderType === "own",
            disabled: _vm.fileSystemLoading,
          },
          on: {
            click: function ($event) {
              return _vm.setCurrentFolderType("own")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("My models")))]
      ),
      _vm.folderTree && _vm.currentFolderType == "own"
        ? _c("cs-filetree", {
            staticClass: "mt-4 mb-8 folder-tree pa-1",
            attrs: {
              items: _vm.folderTree.children,
              value: _vm.currentFolderId,
              loading: _vm.fileSystemLoading,
            },
            on: { input: _vm.navigate },
          })
        : _vm._e(),
      _c(
        "cs-btn",
        {
          staticClass: "justify-start my-2",
          attrs: {
            color: "gray",
            block: "",
            large: "",
            outlined: _vm.currentFolderType === "shared",
            disabled: _vm.fileSystemLoading,
          },
          on: {
            click: function ($event) {
              return _vm.setCurrentFolderType("shared")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("Shared with me")))]
      ),
      _vm.folderTree && _vm.currentFolderType == "shared"
        ? _c("cs-filetree", {
            staticClass: "mt-4 mb-8",
            attrs: {
              items: _vm.folderTree.children,
              value: _vm.currentFolderId,
              loading: _vm.fileSystemLoading,
            },
            on: { input: _vm.navigate },
          })
        : _vm._e(),
      _c(
        "cs-btn",
        {
          staticClass: "justify-start my-2",
          attrs: {
            color: "gray",
            block: "",
            large: "",
            outlined: _vm.currentFolderType === "public",
            disabled: _vm.fileSystemLoading,
          },
          on: {
            click: function ($event) {
              return _vm.setCurrentFolderType("public")
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("Public models")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }