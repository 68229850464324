var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.$t("Create new folder"),
            persistent: "",
            maxWidth: _vm.maxWidth,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [_vm._t("activator", null, null, { on, attrs })]
                },
              },
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "cs-btn",
                      {
                        attrs: { outlined: "", disabled: _vm.loading },
                        on: {
                          click: function ($event) {
                            _vm.showDialog = false
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    ),
                    _c(
                      "cs-btn",
                      {
                        attrs: { disabled: _vm.isEmpty, loading: _vm.loading },
                        on: { click: _vm.createFolder },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Create")) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        "cs-simple-dialog",
        _vm.$props,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "cs-flex",
        { staticClass: "mt-3", attrs: { gap: "2rem", align: "flex-start" } },
        [
          _c("div", { staticClass: "pt-1" }, [
            _vm._v(_vm._s(_vm.$t("Folder name"))),
          ]),
          _c("cs-text-field", {
            attrs: { outlined: "", dense: "", autofocus: "" },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.createFolder.apply(null, arguments)
              },
            },
            model: {
              value: _vm.newFolderName,
              callback: function ($$v) {
                _vm.newFolderName = $$v
              },
              expression: "newFolderName",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }