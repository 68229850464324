var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cs-simple-table", { staticClass: "explorer-list" }, [
    _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th"),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v(_vm._s(_vm.$t("Name")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("Shared")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("Last modified")))]),
        _c("th", [_vm._v(_vm._s(_vm.$t("Size")))]),
      ]),
    ]),
    _c(
      "tbody",
      [
        _vm._l(_vm.currentFolderOrSearchResult.childFolders, function (folder) {
          return _c(
            "tr",
            {
              key: folder.id,
              class: { selected: _vm.selectedItems.includes(folder.id) },
              on: {
                dblclick: function ($event) {
                  return _vm.navigate(folder.id)
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.select($event, folder.id)
                },
              },
            },
            [
              _c(
                "td",
                [
                  _c("v-checkbox", {
                    attrs: {
                      readonly: "",
                      "input-value": _vm.selectedItems.includes(folder.id),
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("mdi-folder"),
                  ]),
                ],
                1
              ),
              _c("td", [
                _c("strong", [_vm._v(_vm._s(folder.name))]),
                _c("div", { staticClass: "text-caption text--secondary" }, [
                  _vm._v(" " + _vm._s(_vm.$t("Folder")) + " "),
                ]),
              ]),
              _c("td"),
              _c("td", [_vm._v("-")]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      Intl.DateTimeFormat().format(
                        new Date(folder.creationDate)
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(_vm._s(_vm.formatSize(folder.size)))]),
            ]
          )
        }),
        _vm._l(_vm.currentFolderOrSearchResult.childModels, function (model) {
          return _c(
            "tr",
            {
              key: model.id,
              class: { selected: _vm.selectedItems.includes(model.id) },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.select($event, model.id)
                },
                dblclick: function ($event) {
                  return _vm.handleFileDoubleClick(model.id)
                },
              },
            },
            [
              _c(
                "td",
                [
                  _c("v-checkbox", {
                    attrs: {
                      readonly: "",
                      "input-value": _vm.selectedItems.includes(model.id),
                    },
                  }),
                ],
                1
              ),
              _c(
                "td",
                [
                  _c(
                    "v-img",
                    {
                      class: `list-preview-image ${
                        _vm.fadedModelKeys.includes(model.id) ? "faded" : ""
                      }`,
                      attrs: { src: model.previewUrl, width: "35" },
                    },
                    [
                      [
                        model.previewUrl
                          ? _vm._t("imageDefaultSlot")
                          : _c(
                              "cs-flex",
                              {
                                attrs: { "fill-width": "", "fill-height": "" },
                              },
                              [_c("default-preview-svg")],
                              1
                            ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("td", [_c("strong", [_vm._v(_vm._s(model.name))])]),
              _c("td"),
              _c(
                "td",
                [
                  model.getShares().length
                    ? _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-account-multiple-outline"),
                      ])
                    : _c("span", [_vm._v("-")]),
                ],
                1
              ),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      Intl.DateTimeFormat().format(
                        new Date(model.lastModificationDate)
                      )
                    ) +
                    " " +
                    _vm._s(
                      new Date(model.lastModificationDate).toLocaleTimeString(
                        undefined,
                        { hour: "2-digit", minute: "2-digit" }
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(_vm._s(model.getFormattedFileSize()))]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }