var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "cs-flex",
        {
          class: ["px-8", "pt-2", { "fs-loading": _vm.fileSystemLoading }],
          staticStyle: { "min-height": "63px" },
          attrs: { justify: "space-between" },
        },
        [
          _vm.fileSystemLoading
            ? _c("v-progress-linear", {
                attrs: { indeterminate: "", absolute: "", top: "" },
              })
            : _vm._e(),
          _c("cs-explorer-breadcrumbs", {
            attrs: { items: _vm.currentPath },
            on: { navigate: _vm.navigate },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function () {
                  return [
                    _c(
                      "v-list",
                      { staticClass: "py-0", attrs: { dense: "" } },
                      [
                        _c(
                          "v-list-item",
                          {
                            attrs: { link: "" },
                            on: {
                              click: function ($event) {
                                _vm.showNewFolderModal = true
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("mdi-folder-plus")])],
                              1
                            ),
                            _c("v-list-item-content", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Create new folder")) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "cs-flex",
            { attrs: { gap: "0.5rem", inline: "" } },
            [
              _c("new-folder-dialog", {
                attrs: {
                  creationTarget: _vm.creationTarget,
                  loading: _vm.newFolderDialogLoading,
                },
                on: { createFolder: _vm.createFolder },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on: onDialog, attrs: attrsDialog }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({
                                    on: onTooltip,
                                    attrs: attrsTooltip,
                                  }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                fab: "",
                                                text: "",
                                                small: "",
                                                disabled: _vm.createDisabled,
                                              },
                                            },
                                            "v-btn",
                                            { ...attrsDialog, ...attrsTooltip },
                                            false
                                          ),
                                          { ...onDialog, ...onTooltip }
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-folder-plus-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("Create new folder"))),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.showNewFolderModal,
                  callback: function ($$v) {
                    _vm.showNewFolderModal = $$v
                  },
                  expression: "showNewFolderModal",
                },
              }),
              _c("move-to-dialog", {
                attrs: {
                  folders: _vm.folders,
                  currentFolderId: _vm.currentFolderId,
                  selectedItems: _vm.selectedItems,
                  loading: _vm.moveToDialogLoading,
                },
                on: {
                  moveFiles: _vm.moveFiles,
                  createNewFolder: _vm.openNewFolderModal,
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on: onDialog, attrs: attrsDialog }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({
                                    on: onTooltip,
                                    attrs: attrsTooltip,
                                  }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                fab: "",
                                                text: "",
                                                small: "",
                                                disabled: _vm.moveDisabled,
                                              },
                                            },
                                            "v-btn",
                                            { ...attrsDialog, ...attrsTooltip },
                                            false
                                          ),
                                          { ...onDialog, ...onTooltip }
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-folder-move-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Move to")))])]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.showMoveToModal,
                  callback: function ($$v) {
                    _vm.showMoveToModal = $$v
                  },
                  expression: "showMoveToModal",
                },
              }),
              _c("share-dialog", {
                attrs: {
                  value: _vm.showShareModal,
                  shares: _vm.selectedModelShares,
                  selectedModel: _vm.selectedModel,
                },
                on: { input: _vm.setShowShareModal },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on: onDialog, attrs: attrsDialog }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({
                                    on: onTooltip,
                                    attrs: attrsTooltip,
                                  }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                fab: "",
                                                text: "",
                                                small: "",
                                                disabled: _vm.shareDisabled,
                                              },
                                            },
                                            "v-btn",
                                            { ...attrsDialog, ...attrsTooltip },
                                            false
                                          ),
                                          { ...onDialog, ...onTooltip }
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-share-variant-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("Share")))])]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("explorer-delete-file", {
                attrs: { disabled: _vm.deleteDisabled },
                on: {
                  submit: function ($event) {
                    return _vm.$emit("deleteFile")
                  },
                },
              }),
              _c("cs-text-field", {
                attrs: {
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  placeholder: _vm.$t("Search in current folder..."),
                  "append-icon": "mdi-magnify",
                  clearable: "",
                  value: _vm.searchKeyword,
                },
                on: { input: _vm.handleChangeSearchKeyword },
              }),
              _c("cs-select", {
                staticClass: "ordering-select",
                attrs: {
                  dense: "",
                  solo: "",
                  flat: "",
                  "hide-details": "",
                  "background-color": "transparent",
                  "append-icon": "mdi-chevron-down",
                  "menu-props": { bottom: true, offsetY: true },
                  value: _vm.orderBy,
                  items: _vm.orderByOptions,
                },
                on: { input: _vm.handleChangeOrderBy },
              }),
              _c("v-divider", { staticClass: "my-2", attrs: { vertical: "" } }),
              _c("view-mode-select", {
                attrs: { value: _vm.viewMode },
                on: { input: _vm.handleChangeViewMode },
              }),
              _c("v-divider", { staticClass: "my-2", attrs: { vertical: "" } }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    fab: "",
                                    text: "",
                                    small: "",
                                    color: _vm.infoBarToggle
                                      ? "primary"
                                      : undefined,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setInfoBarToggle(
                                        !_vm.infoBarToggle
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-information-outline")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("View details")))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.searchKeyword
        ? _c(
            "h4",
            {
              class: ["px-8", { "fs-loading": _vm.fileSystemLoading }],
              staticStyle: { "font-weight": "400" },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("Search results for:") + ` ${_vm.searchKeyword}`
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }