var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.$t("Move to"),
            persistent: "",
            "min-height": "25rem",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [_vm._t("activator", null, null, { on, attrs })]
                },
              },
              {
                key: "actions",
                fn: function () {
                  return [
                    _c(
                      "cs-btn",
                      {
                        staticClass: "mr-auto",
                        attrs: { text: "", small: "", disabled: _vm.loading },
                        on: { click: _vm.openNewFolderDialog },
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-2", attrs: { small: "" } },
                          [_vm._v("mdi-folder-plus-outline")]
                        ),
                        _vm._v(" " + _vm._s(_vm.$t("Add folder")) + " "),
                      ],
                      1
                    ),
                    _c(
                      "cs-btn",
                      {
                        staticClass: "mr-4",
                        attrs: { outlined: "", disabled: _vm.loading },
                        on: {
                          click: function ($event) {
                            _vm.showDialog = false
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          left: "",
                          disabled: _vm.allowedLocation,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ attrs, on }) {
                              return [
                                _c(
                                  "cs-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          loading: _vm.loading,
                                          disabled:
                                            _vm.loading || !_vm.allowedLocation,
                                        },
                                        on: { click: _vm.moveFiles },
                                      },
                                      "cs-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" " + _vm._s(_vm.$t("Move")) + " ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("Folder cannot be moved into itself"))
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        "cs-simple-dialog",
        _vm.$props,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("cs-explorer-breadcrumbs", {
        attrs: { items: _vm.currentPath, small: "" },
        on: { navigate: _vm.navigate },
      }),
      _c("v-system-bar", { attrs: { height: "1.5" } }),
      _vm.folderMap
        ? _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.folderMap.childFolders, function (folder) {
              return _c(
                "cs-list-item",
                {
                  key: folder.id,
                  on: {
                    click: function ($event) {
                      return _vm.navigate(folder.id)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-folder")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(_vm._s(folder.name))])],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }