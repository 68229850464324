
import { CsBtn, CsFlex, CsTextField, CsSimpleDialog } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'NewFolderDialog',
  components: {
    CsSimpleDialog,
    CsFlex,
    CsTextField,
    CsBtn,
  },
  props: {
    value: Boolean,
    error: String,
    maxWidth: { type: Number, default: 450 },
    creationTarget: { type: String, default: '' },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      newFolderName: '',
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value
      },
      set(newVal: boolean): void {
        this.$emit('input', newVal)
      },
    },
    isEmpty(): boolean {
      return this.newFolderName.trim() === ''
    },
  },
  methods: {
    createFolder() {
      if (!this.isEmpty) {
        this.$emit('createFolder', this.newFolderName.trim(), this.creationTarget)
      }
    },
  },

  watch: {
    value() {
      this.newFolderName = ''
    },
  },
})
